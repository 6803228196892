@use '../globals';
@use '../flex';
@use '../vendored';

main {
  margin: 0 70px;
}

#skip-main-nav-button {
  background: globals.$clr-hover;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  top: 5px;
  left: 20px;
  z-index: 102;
}

#main-nav-button {
  background: globals.$clr-nav;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: block;
  position: fixed;
  top: 40px;
  left: 20px;
  z-index: 101;

  @include flex.centre();
  -webkit-transition: left 350ms, background-color 50ms;
  -moz-transition: left 350ms, background-color 50ms;
  -ms-transition: left 350ms, background-color 50ms;
  -o-transition: left 350ms, background-color 50ms;
  transition: left 350ms, background-color 50ms;

  .material-icons-outlined {
    font-size: 30px;
  }

  &:hover {
    background: globals.$clr-hover;
  }

  &:focus {
    background: globals.$clr-hover;
  }

  &.open {
    left: calc(globals.$collapsingnav-width - 80px);
  }
}

#main-menu.collapsing {
  background: globals.$clr-nav;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 50px 0 250px 0;
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  @include vendored.trs(350ms);

  &.open {
    width: globals.$collapsingnav-width;
    padding: 50px;
  }

  .material-icons-outlined {
    display: inline-block;
    margin-right: 20px;
    font-size: 1.3em;
  }

  li {
    list-style: none;
    overflow: hidden;
    white-space: nowrap;

    &.text {
      padding: 11px;
    }

    &.divider {
      margin: 15px 0;
    }

    h2 {
      display: inline-flex;
      align-items: center;
      font-size: 1.5rem;
    }

    a {
      margin: 0;
      padding: 11px;
      display: inline-flex;
      align-items: center;

      color: white;
      text-decoration: none;

      &.link {
        color: globals.$clr-accent;
        text-decoration: underline;
      }

      &:focus {
        outline: none;
        border-left: 3px solid globals.$clr-accent;
      }
    }
  }
}

#main-menu.collapsing .submenu {
  padding: 0 calc(2.34rem + 9px);

  .material-icons-outlined {
    margin-right: 10px;
    // font-size: 1.5em;
  }

  li {
    // font-size: 1.15rem;
    // margin-bottom: 0.2em;

    a {
      // font-size: 1.15rem;

      &:hover {
        background: globals.$clr-hover;
      }
    }
  }
}


@media only screen and (max-width: 900px) {
  #main-nav-button {
    &.open {
      left: initial;
      right: 30px;
    }
  }

  #main-menu.collapsing {
    display: flex;
    justify-content: center;

    &.open {
      width: 100vw;
    }
  }
}