@mixin flex($jc: center, $ai: center, $dir: column) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: $dir;
}

@mixin centre($dir: column) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $dir;
}

@mixin space-between($dir: column) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: $dir;
}

@mixin space-around($dir: column) {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: $dir;
}